export const environment = {
  "baseServiceUri": "https://services.food.getmiles.co",
  "truckId": 480,
  "fb_pixel_id": "",
  "truck_name": "royalmachboos",
  "logo": "/assets/images/logo.png",
  "allow_promo_codes": true,
  "disable_cash": true,
  "display_home": false,
  "allow_pre_order": true,
  "currency_code": "kwd",
  "snapchat_pixel_id": "",
  "userPhone": "",
  "userMail": "",
  "tiktokPixle": "",
  "defaultCategoryMode": "LIST",
  "categoryMode": "LIST",
  "showAllAddressTypes": true,
  "secondryColor": "#d1b056",
  "enable_OTP_Phone": false,
  "allow_Cash_Only": true,
  "use_category_mask": false,
  "use_item_controls": true,
  "use_big_cards": false,
  "use_basket_cart": false,
  "ask_for_substitution": false,
  "allow_terms": false,
  "use_big_category": true,
  "gtag": null,
  "clarity": null,
  "production": true,
  "app_version": "1.4",
  "defLogo": "/assets/images/logo.png",
  "defItemPic": "/assets/images/item.png",
  "decimalPoints": 3,
  "card_payment": "myfatoorah",
  "use_inline_item_card": true,
  "preorder_days": 1,
  "preorder_only": false,
  "allow_membership_number": false,
  "preorder_note": {
    "ar": "",
    "en": ""
  },
  "show_area": true,
  "area_required": true,
  "enable_email_at_checkout": false,
  "domain": "",
  "arabic": true,
  "english": true,
  "default_lang": "ar",
  "allow_shipping": false,
  "default_country": 112,
  "default_category_id": null,
  "req_delivery_time": true,
  "req_country": true,
  "req_governate": true,
  "req_area": true,
  "req_block": false,
  "req_avenue": false,
  "req_street": false,
  "req_house": false,
  "req_floor": false,
  "req_flat": false,
  "req_address_line": false,
  "req_delivery_instructions": false,
  "req_payment_method": true,
  "req_card_holder": false,
  "req_card_number": false,
  "req_address_cvv": false,
  "req_store_address": false,
  "req_promo_code": false,
  "terms_at_end_of_menu": false
}